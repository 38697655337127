// Raw (not MyJones-specific) interaction with the Shopify Cart API.
// Note these need to be called from within our Shopify site.
// E.g. it will GET https://www.jonessoda.com/products/myjones-custom-labeled-soda.json


// Based on: https://nozzlegear.com/shopify/using-javascript-to-manage-a-shopify-cart

// item is an object with keys like "id", "quantity", "properties[Prop Name]"
// https://shopify.dev/docs/themes/ajax-api/reference/cart
export async function addToShopifyCart(cartItem) {
  const formData = new FormData();

  for(const key in cartItem) {
    formData.append(key, cartItem[key]);
  }

  // This only works from live site due to CORS
  const result = await fetch("https://www.jonessoda.com/cart/add.json", {
    method: "POST",
    body: formData
  });

  return result.json();
}


// Get Shopify product details. Example to get based on the current page URL:
//const product = await getShopifyProduct(window.location.href + '.json');
//console.log({ title: product.title, handle: product.handle });
export async function getShopifyProduct(productJsonUrl) {
  // DEBUG ONLY below: Draft product. URL needs updating periodically
  //const result = await fetch(`https://wio82pfy3eh4gmbs-10887276.shopifypreview.com/products_preview.json?preview_key=c853b2462391f274a03f10908ebe8476`, {

  const result = await fetch(productJsonUrl, {
    mode: 'cors',
    headers: {
      "Accept": "application/json"
    }
  });

  return (await result.json()).product;
}


