import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './TextPage.module.scss';
import { BackTextField } from './BackTextField';
import { setBackLabelSize, setBackLabelAlignment } from '../customSlice';

export function TextPage() {
  const backLabelSize = useSelector((state) => state.custom.backLabelSize);
  const backLabelAlignment = useSelector((state) => state.custom.backLabelAlignment);

  const dispatch = useDispatch();

  function changeSize(e) {
    dispatch(setBackLabelSize(e.target.value));
  }

  function changeAlignment(e) {
    dispatch(setBackLabelAlignment(e.target.value));
  }

  return (
    <section className={styles.textPage}>
      <h2>Back Text</h2>

      <BackTextField />

      <h3>Back Text Size</h3>

      <select onChange={changeSize} value={backLabelSize}>
        <option value="6">Small</option>
        <option value="9">Medium</option>
        <option value="15">Large</option>
      </select>

      <h3>Back Text Alignment</h3>

      <select onChange={changeAlignment} value={backLabelAlignment}>
        <option value="left">Left</option>
        <option value="center">Center</option>
        <option value="right">Right</option>
      </select>

    </section>
  );
}

