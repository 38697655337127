import React from 'react';
import { useSelector } from 'react-redux';

import { ExplanationParagraph } from '../../../components/ExplanationParagraph';

export function UpsellPage() {
  const photo = useSelector((state) => state.custom.photo);

  const upsellToShirts = () => {
    try {
      window.sessionStorage.setItem('myJonesCustomPhoto', photo);
    } catch(err) {
      console.error(err);
    }

    window.location.href = 'https://www.jonessoda.com/products/jones-soda-custom-t-shirts';
  };

  return (
    <section>
      <h2>Done!</h2>
      <ExplanationParagraph>Your order is in your cart.</ExplanationParagraph>
      <p><button className="btn" onClick={upsellToShirts}>Put your photo on custom t-shirts too!</button></p>
      <p><a className="btn" href="https://www.jonessoda.com/cart">Check out</a></p>
      <p><a href="https://www.jonessoda.com/products/myjones-custom-labeled-soda">Add more custom bottles</a></p>
    </section>
  );
}
