import React from 'react';
import { useSelector } from 'react-redux';

import { getAvailableFlavors } from '../../shopify/myJonesShopifyAPI';

import { FlavorItem } from './FlavorItem';
import { Spinner } from '../../../components/Spinner';

export function FlavorPage() {
  const product = useSelector((state) => state.shopify.product);
  const theme   = useSelector((state) => state.custom.theme);

  if(product === null) {
    // Shouldn't be needed if Theme page also requires product data?
    return <Spinner />;

  } else {
    const flavors = getAvailableFlavors(product, theme);
    const flavorsElements = flavors.map((flavor, i) => <FlavorItem flavor={flavor} key={i} />);

    return (
      <section>
        <h2>Choose a Flavor</h2>
        {flavorsElements}
      </section>
    );
  }
}

