import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addToCart } from './shopifySlice';
import { setHighlightMissingPage } from '../nav/navSlice';
import { selectMissingPage } from '../pages/customSlice';

export function SubmitButton() {
  const missingPage = useSelector((state) => selectMissingPage(state, 'review'));
  const isAddToCartLoading = useSelector((state) => state.shopify.isAddToCartLoading);
  const dispatch = useDispatch();

  function clickAddToCart() {
    if(missingPage !== null) {
      dispatch(setHighlightMissingPage(missingPage));
    } else {
      dispatch(addToCart());
    }
  }

  return (
    <button
      onClick={clickAddToCart}
      disabled={isAddToCartLoading}
    >
      Add to Cart
    </button>
  );
}
