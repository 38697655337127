import React from 'react';

import styles from './Nav.module.scss';
import { NavItem } from './NavItem';
import { SubmitButton } from '../shopify/SubmitButton';

export function Nav() {
  return (
    <nav className={styles.nav}>
      <NavItem
        pageId='theme'
        name='Label Theme' />
      <NavItem
        pageId='flavor'
        name='Flavor'
        highlightMessage='Next, choose a flavor!' />
      <NavItem
        pageId='photo'
        name='Photo'
        highlightMessage='Next, upload a photo!' />
      <NavItem
        pageId='text'
        name='Text'
        highlightMessage='Write a custom message for the back of your bottle.' />
      <NavItem
        pageId='quantity'
        name='Quantity'
        highlightMessage='Now choose a quantity.' />
      <NavItem
        pageId='review'
        name='Review' />
      
      <SubmitButton />
    </nav>
  );
}
