import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SelectItem } from '../../../components/SelectItem';
import { setFlavor } from '../customSlice';

export function FlavorItem(props) {
  const currentFlavor = useSelector((state) => state.custom.flavor);
  const dispatch = useDispatch();

  function clickFlavor() {
    dispatch(setFlavor(props.flavor));
  }

  return (
    <SelectItem
     onClick={clickFlavor}
     isActive={currentFlavor === props.flavor}
    >
      {props.flavor}
    </SelectItem>
  );
}
