import { configureStore } from '@reduxjs/toolkit';
import navReducer from '../features/nav/navSlice';
import customReducer from '../features/pages/customSlice';
import shopifyReducer from '../features/shopify/shopifySlice';

export const store = configureStore({
  reducer: {
    nav: navReducer,
    custom: customReducer,
    shopify: shopifyReducer,
  },
});
