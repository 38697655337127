import React from 'react';
import classNames from 'classnames';

import styles from './ExplanationParagraph.module.scss';

export function ExplanationParagraph(props) {
  return (
      <p className={classNames(styles.explanationParagraph, { [styles.hideOnSmall]: props.responsive==='hide-on-small' })}>
        {props.children}
      </p>
  );
}
