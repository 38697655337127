import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProduct } from './shopifySlice';

// Component to load product config into state only once

export function ProductLoader() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.shopify.product);
  useEffect(() => {
    if(product === null) {
      dispatch(fetchProduct());
    }
  });

  return <></>;
}
