import { getShopifyProduct, addToShopifyCart } from './shopifyAPI';

// MyJones-customized versions of the Shopify API calls
// and parsers of the product data. Don't getShopifyProduct() ourselves here.
// Pass state.shopify.product into parser functions.

// The options aren't named but have a fixed order: Theme, Flavor, Case Size
// product.options[0] corresponds to variant.option1

export async function getMyJonesProduct() {
  // Get Product data from Shopify and add our template data to the variants

  // Determine the product based on the containing web page URL
  // Later we can look at product.handle instead
  let productJsonUrl = `${window.location.origin}${window.location.pathname}.json${window.location.search}${window.location.hash}`;

  if(window.location.href.indexOf('custom-dog-myjones-soda') !== -1) {
    // Should be debug only--allows in query string etc.
    productJsonUrl = 'https://www.jonessoda.com/products/custom-dog-myjones-soda.json';

  } else if(productJsonUrl.indexOf('jonessoda.com') === -1 && productJsonUrl.indexOf('shopifypreview.com') === -1) {
    // Use default MyJones product if we don't detect we're on a Shopify page
    productJsonUrl = 'https://www.jonessoda.com/products/myjones-custom-labeled-soda.json';

  }

  const [product, variantsTemplateData] = await Promise.all([
    getShopifyProduct(productJsonUrl),
    fetchVariantsTemplateData(),
  ]);

  product.variants.forEach((variant) => {
    if(variantsTemplateData[variant.id]) {
      // We'll now have, e.g., product.variants[n].template.template_id = 'regular-001-oc', as well as font_color, other formatting data
      variant.template = variantsTemplateData[variant.id];
    } else {
      variant.template = null;
    }
  });

  // Don't show variants where the template isn't configured
  product.variants = product.variants.filter((variant) => {
    return (variant.template !== null);
  });

  return product;
}


// Response: { variants: { 1234567: { template_id: '', font_color: '', ... }, ... }}
export async function fetchVariantsTemplateData() {
  const response = await fetch('https://myjones.jonessoda.com/v2/api/variants', {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  });

  return (await response.json()).variants;
}


export async function addMyJonesToCart(args) {
  const { product, custom } = args;
  const variant = getVariant(product, custom.theme, custom.flavor, custom.caseSize);

  const frontImage = await convertPhotoStringToFile(custom.photo);

  return addToShopifyCart({
    id:                         variant.id,
    quantity:                   custom.quantity,
    'properties[Front Image]':  frontImage,
    'properties[Back Text]':    custom.backLabelText,
    'properties[Back Text Font Size]':    custom.backLabelSize,
    'properties[Back Text Alignment]':    custom.backLabelAlignment,
  });
}


// state.custom.photo is a data:image string. Posting that string straight to the cart almost works but Shopify
// treats it differently (on the cart preview, for example). Instead convert it to a binary Blob and let the
// FormData object encode it appropriately.
async function convertPhotoStringToFile(dataURL) {
  // https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript

  const blob = await (await fetch(dataURL)).blob(); // blob includes mime-type but no filename
  const file = new File([blob], 'Submitted Photo.jpg', { type: 'image/jpeg' }); // add filename so Shopify saves as .jpg
  return file;
}


export function getAvailableThemes(product) {
  const allThemes = product.options[0].values; // "Regular", "Grad", etc.

  // This isn't an efficient algorithm but the list of themes will be short
  const themeHasInventory = (theme) => {
    return product.variants.some((variant) => {
      return variant.option1 === theme
      && (variant.inventory_quantity > 0
          || variant.inventory_policy === 'continue');
    });
  };

  const availableThemes = allThemes.filter(themeHasInventory);
  return availableThemes;
}


export function getAvailableFlavors(product, theme) {
  const filteredVariants = product.variants.filter((variant) => {
    return variant.option1 === theme
    && (variant.inventory_quantity > 0
        || variant.inventory_policy === 'continue')
  });

  const flavors = filteredVariants.map((variant) => variant.option2);
  const distinctFlavors = [...new Set(flavors)]; // remove duplicates due to multiple case sizes per flavor
  return distinctFlavors;
}


export function getAvailableCaseSizes(product, theme, flavor) {
  const filteredVariants = product.variants.filter((variant) => {
    return variant.option1 === theme
    &&  variant.option2 === flavor
    && (variant.inventory_quantity > 0
        || variant.inventory_policy === 'continue')
  });

  const caseSizes = filteredVariants.map((variant) => variant.option3);
  return caseSizes;
}


export function getDefaultTemplate(product, theme, flavor) {
  if(product === null || theme === null) return null;

  const filteredVariants = product.variants.filter((variant) => {
    return variant.option1 === theme
    && (variant.option2 === flavor || flavor === null) // optional filter
    && variant.template !== null
  });

  // Pick one in-stock if possible
  const inStockVariants = filteredVariants.filter((variant) => {
    return (variant.inventory_quantity > 0
        || variant.inventory_policy === 'continue')
  });

  // This returns the first. We may in future select the default on some other criteria
  if(inStockVariants.length > 0) {
    return inStockVariants[0].template;

  } else if(filteredVariants.length > 0) {
    return filteredVariants[0].template;

  } else {
    return null;
  }
}


// Get a variant object from theme/flavor/caseSize strings.
// Lets you access variant.id, variant.price, variant.sku, etc.
export function getVariant(product, theme, flavor, caseSize) {

  if(product === null || theme === null || flavor === null || caseSize === null) {
    return null;
  }

  const variant = product.variants.find((variant) => {
    return variant.option1 === theme
    &&  variant.option2 === flavor
    &&  variant.option3 === caseSize
    && (variant.inventory_quantity > 0
        || variant.inventory_policy === 'continue')
  });

  return variant; // null if no match
}


/*
 * Finding valid combinations:
 * Look through product.variants array.
 * We'll see properties of each variant, like:
 * {
 *  id: 12345,                // variant ID needed for adding to cart
 *  inventory_quantity: 100,  // disallow combinations out of stock
 *  price: "49.99",           // show to user
 *  sku: "MY-001W-6",         // indicates flavor and pack size a la options 1, 3
 *  position: 11,             // if we want to use Shopify sort order
 *  option1: "Holiday",
 *  option2: "Cream Soda",
 *  option3: "12",
 * }
 */

