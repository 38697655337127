import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import styles from './PhotoPage.module.scss';
import { setPhoto } from '../customSlice';

export function PhotoPage() {
  const hasPhoto = (useSelector((state) => state.custom.photo) !== null);
  const dispatch = useDispatch();

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if(file) {
      if(! ['image/jpeg', 'image/jpg', 'image/pjpeg'].includes(file.type)) {
        alert('Please upload a JPG image.');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => { dispatch(setPhoto(reader.result)) };
      reader.readAsDataURL(file);
    }
  }, [dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

  let dropMessage;
  if(hasPhoto) {
    dropMessage = (
      <div>
        <p>Got it!</p>
        <p>To replace your photo, drag and drop your photo here or:</p>
        <button>Browse Files</button>
      </div>
    );

  } else {
    dropMessage = (
      <div>
        <p>Drag and drop your photo here, or:</p>
        <button>Browse Files</button>
      </div>
    );
  }

  return (
    <section {...getRootProps({ className: classNames(styles.photoPage, { [styles.dragActive]: isDragActive }) })}>
      <h2>Upload a Photo</h2>

      <div className={styles.dropMessage}>
        <input {...getInputProps()} />
        {dropMessage}
      </div>
    </section>
  );
}

