import React from 'react';

import Loader from "react-loader-spinner";

export function Spinner() {
  return (
      <Loader
        type="ThreeDots"
        color="#999999"
        height={100}
        width={100}
      />
  );
}
