import { createSlice } from '@reduxjs/toolkit';

// The "custom" slice: The bottle/label properties that the customer chooses

import { getVariant, getDefaultTemplate } from '../shopify/myJonesShopifyAPI';

let initialPhoto = null;
try {
  initialPhoto = window.sessionStorage.getItem('myJonesCustomPhoto');
} catch(err) {
  console.error(err);
}

const initialState = {
  theme: 'Regular', // 'Regular'|'Grad'|'Wedding'|'Holiday',
  flavor: null, // the option string from Shopify, e.g. 'Orange & Cream'
  caseSize: null, // 1|6|12,
  quantity: 1,
  photo: initialPhoto, // as data URL

  backLabelText: '',
  backLabelSize: 9, // 6|9|15, // labelled as Small/Medium/Large
  backLabelAlignment: 'center', // 'left'|'center','right',
};

export const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      state.flavor = null;
      state.caseSize = null;
    },
    
    setFlavor: (state, action) => {
      state.flavor = action.payload;
      state.caseSize = null;
    },

    setCaseSize: (state, action) => {
      state.caseSize = action.payload;
    },

    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },

    setPhoto: (state, action) => {
      state.photo = action.payload;
    },

    setBackLabelText: (state, action) => {
      state.backLabelText = action.payload;
    },

    setBackLabelSize: (state, action) => {
      state.backLabelSize = action.payload;
    },

    setBackLabelAlignment: (state, action) => {
      state.backLabelAlignment = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { setTheme, setFlavor, setCaseSize, setQuantity, setPhoto,
  setBackLabelText, setBackLabelSize, setBackLabelAlignment }
  = customSlice.actions;


// Usage: missingPage = useSelector((state) => selectMissingPage(state, 'review'));
export const selectMissingPage = (state, forPageId) => {
  if(['photo', 'text', 'quantity', 'review'].includes(forPageId)) {
    if(state.custom.theme === null) {
      return 'theme';

    } else if(state.custom.flavor === null) {
      return 'flavor';
    }
  }

  if(['review'].includes(forPageId)) {
    if(state.custom.photo === null) {
      return 'photo';

    } else if(state.custom.caseSize === null || state.custom.quantity === null) {
      return 'quantity';
    }
  }

  return null;
};


export const selectCurrentVariant = (state) => {
  return getVariant(
    state.shopify.product,
    state.custom.theme,
    state.custom.flavor,
    state.custom.caseSize,
  ); // null if no match or if some values above are null
};


// To be able to show bottle images if no variant is selected yet
export const selectCurrentTemplate = (state) => {
  const variant = selectCurrentVariant(state);

  if(variant && ! variant.template) {
    // If no template, look for default (e.g. useful for a new case size).
    // However this may hide variants that aren't configured in the database!
    console.warn(`Variant ${variant.id} is missing template data`);
  }

  if(variant && variant.template) {
    return variant.template;

  } else if(state.custom.theme) {
    return getDefaultTemplate(
      state.shopify.product,
      state.custom.theme,
      state.custom.flavor,  // may be null
    );

  } else {
    return null;
  }
};


export default customSlice.reducer;
