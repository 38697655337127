import { createSlice } from '@reduxjs/toolkit';

import { addToCart } from '../shopify/shopifySlice';

const initialState = {
  currentPageId: 'theme',
  highlightMissingPageId: null,
  isSubmitting: false,
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPageId = action.payload;
      state.highlightMissingPageId = null;
    },

    setHighlightMissingPage: (state, action) => {
      state.highlightMissingPageId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        // features/shopify/shopifySlice.js also listens for ths
        state.currentPageId = 'upsell';
      });    
  },
});

export const { setPage, setHighlightMissingPage } = navSlice.actions;

export default navSlice.reducer;
