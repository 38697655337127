import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SelectItem } from '../../../components/SelectItem';
import { setTheme } from '../customSlice';

export function ThemeItem(props) {
  const currentTheme = useSelector((state) => state.custom.theme);
  const dispatch = useDispatch();

  function clickTheme() {
    dispatch(setTheme(props.theme));
  }

  return (
    <SelectItem
     onClick={clickTheme}
     isActive={currentTheme === props.theme}
    >
      {props.theme}
    </SelectItem>
  );
}
