import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './App.module.scss';

import { ProductLoader } from './features/shopify/ProductLoader';
import { CurrentPage } from './features/pages/CurrentPage';
import { BottleFront } from './features/preview/BottleFront';
import { Nav } from './features/nav/Nav';

function App() {
  const currentPageId = useSelector((state) => state.nav.currentPageId);

  return (
    <div className={classNames(styles.app, styles["currentPageId-"+currentPageId])}>
      <ProductLoader />
      <div className={styles.mainRow}>
        <CurrentPage className={styles.currentPage} />
        <BottleFront className={styles.bottleFront} />
      </div>
      {currentPageId !== 'upsell' && <Nav />}
    </div>
  );
}

export default App;
