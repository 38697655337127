import React from 'react';
import { useSelector } from 'react-redux';

import { getAvailableThemes } from '../../shopify/myJonesShopifyAPI';

import styles from './ThemePage.module.scss';
import { ThemeItem } from './ThemeItem';
import { Spinner } from '../../../components/Spinner';
import { ExplanationParagraph } from '../../../components/ExplanationParagraph';

export function ThemePage() {
  const product = useSelector((state) => state.shopify.product);

  if(product === null) {
    // Product data loaded async from Shopify
    return <Spinner />;

  } else {
    const themes = getAvailableThemes(product);
    const themesElements = themes.map((theme, i) => <ThemeItem theme={theme} key={i} />);

    return (
      <section className={styles.themeSection}>
        <h2>Choose a Label Theme</h2>
        {themesElements}

        {product.handle === 'myjones-custom-labeled-soda' && /* exclude Dog Soda */
          <div className={styles.corporateLink}><ExplanationParagraph><a href="/pages/corp-myjones">If you are interested in corporate/bulk order information, please click here!</a></ExplanationParagraph></div>
        }
      </section>
    );
  }
}

