import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMyJonesProduct, addMyJonesToCart } from './myJonesShopifyAPI';

// The "shopify" slice: Store the Shopify product data for our MyJones product,
// which determines flavors, inventory, etc.
// We also append data about each variant from our own database (see getMyJonesProduct)

const initialState = {
  isProductLoading: false,
  isAddToCartLoading: false,
  product: null,
};

export const fetchProduct = createAsyncThunk(
  'shopify/fetchProduct',
  async () => {
    const product = await getMyJonesProduct();
    return product;  // The value we return becomes the `fulfilled` action payload
  }
);

export const addToCart = createAsyncThunk(
  'shopify/addToCart',
  async (unusedArg, { getState }) => {
    const state = getState();
    const { custom } = state;
    const { product } = state.shopify;
    const response = await addMyJonesToCart({ product, custom });
    return response;  // The value we return becomes the `fulfilled` action payload
  }
);

export const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    //setTheme: (state, action) => {
    //  state.theme = action.payload;
    //},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.isProductLoading = false;
        state.product = action.payload;
      })
      .addCase(addToCart.pending, (state) => {
        state.isAddToCartLoading = true;
      })
      .addCase(addToCart.rejected, (state, action) => {
        // TO-DO: Show error
        console.error(action.error);
        state.isAddToCartLoading = false;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isAddToCartLoading = false;
        // features/nav/navSlice.js also listens for this and sets up next steps
      });
  },
});

//export const { setTheme } = shopifySlice.actions;

export default shopifySlice.reducer;
