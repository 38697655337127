import React from 'react';
import classNames from 'classnames';

import styles from './SelectItem.module.scss';

export function SelectItem(props) {
  return (
      <div
        onClick={props.onClick}
        className={ classNames(props.styles, styles.selectItem, { [styles.active]: props.isActive }) }
      >
        <input type="radio" checked={props.isActive} readOnly />
        <div>{props.children}</div>
      </div>
  );
}
