import React, { useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { setBackLabelText, selectCurrentTemplate } from '../customSlice';

import styles from './BackTextField.module.scss';

export function BackTextField(props) {
  const backLabelText = useSelector((state) => state.custom.backLabelText);
  const backLabelSize = useSelector((state) => state.custom.backLabelSize);
  const backLabelAlignment = useSelector((state) => state.custom.backLabelAlignment);

  const template = useSelector((state) => selectCurrentTemplate(state));
  
  const dispatch = useDispatch();

  const [ showIllegalCharsWarning, setShowIllegalCharsWarning ] = useState(false);

  // Check if text fits
  const textareaRef = useRef(null);
  const [showLongTextWarning, setShowLongTextWarning] = useState(false);
  useLayoutEffect(() => {
    if(textareaRef.current) {
      // TO-DO: hard wrap won't work directly in React - appears as soft wrap.
      // Options:
      // - Build our own hard wrap in React component
      // - Wrap on server side
      // - Can we extract wrapped text via FormData or similar? didn't work
      //const tooWide = !! (textareaRef.current.clientWidth < textareaRef.current.scrollWidth);
      //if(tooWide) {
      //  // ... do wrap ...
      //  dispatch(setBackLabelText(newText));
      //}

      const tooLong = !! (textareaRef.current.clientHeight < textareaRef.current.scrollHeight);
      setShowLongTextWarning(tooLong);
    }
  }, [backLabelText, backLabelSize]);


  function handleChange(e) {
    //const wrapped = new FormData(e.target.form);
    //console.log({ wrapped: wrapped.get('backTextField') });

    if(! /^[ -~\r\n]*$/.test(e.target.value)) {
      setShowIllegalCharsWarning(true);
    } else {
      setShowIllegalCharsWarning(false);
      dispatch(setBackLabelText(e.target.value));
    }
  }

  // "https://myjones.jonessoda.com/app/static/front-end/bottle-back-text/bottle-back-text-my007.png?v=20210121";
  const backgroundUrl = (template)
    ? `https://myjones.jonessoda.com/v2/static/front-end/bottle-back-text/${template.template_id}.png`
    : null;

  const fontColor = (template)
    ? template.font_color
    : '#ffffff';

  return (
    <div>
      <div
       className={classNames(styles.textAreaWrapper, styles["size-"+backLabelSize], styles["align-"+backLabelAlignment])}
       style={{backgroundImage: "url(" + backgroundUrl + ")"}}
      >
      <form>{/* Wrapper and textarea name needed to use FormData */}
        <textarea
          name='backTextField'
          ref={textareaRef}
          onChange={handleChange}
          value={backLabelText}
          placeholder="Enter custom text here for the back of the bottle."
          wrap="hard"
          style={{color: fontColor}}
        />
      </form>
      </div>

      {showIllegalCharsWarning &&
        <div className={styles.warning}>
          Your text should only contain basic letters, numbers, and punctuation. Symbols and emojis won't work. It's better to type it directly here than to copy and paste.
        </div>
      }
      
      {showLongTextWarning &&
        <div className={styles.warning}>
          Hey, you entered too much text to fit on a label! You'll need to shorten it
          {backLabelSize !== '6' && ' or choose a smaller font size'}.
        </div>
      }
    </div>
  );
}
