import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectCurrentTemplate } from '../pages/customSlice';
import styles from './BottleFront.module.scss';

export function BottleFront(props) {
  const product   = useSelector((state) => state.shopify.product);
  const template  = useSelector((state) => selectCurrentTemplate(state));
  const photoData = useSelector((state) => state.custom.photo);

  let bottleImageUrl = null;
  let overlayImageUrl = null;
  const lightingOverlayImageUrl = 'https://myjones.jonessoda.com/v2/static/front-end/bottle-lighting-overlay.png';

  if(template) {
    bottleImageUrl = `https://myjones.jonessoda.com/v2/static/front-end/bottle-front-3d/${template.template_id}.png`;
    overlayImageUrl = (template.use_overlay_image)
      ? `https://myjones.jonessoda.com/v2/static/front-end/bottle-front-overlay/${template.template_id}.png`
      : null;
  }

  function SectionWrapper(innerProps) {
    return <section className={classNames(styles.bottleFront, props.className, { [styles.dogSoda]: product && product.handle === 'custom-dog-myjones-soda'})}>{innerProps.children}</section>;
  }

  if(bottleImageUrl) {
    return (
      <SectionWrapper>
        <div className={styles.bottleImage} style={{backgroundImage: "url(" + bottleImageUrl + ")"}}></div>
        {photoData && <div className={styles.customImage} style={{backgroundImage: "url(" + photoData + ")"}}></div>}
        {overlayImageUrl && <div className={styles.overlayImage} style={{backgroundImage: "url(" + overlayImageUrl + ")"}}></div>}
        <div className={styles.lightingOverlayImage} style={{backgroundImage: "url(" + lightingOverlayImageUrl + ")"}}></div>
      </SectionWrapper>
    );
  } else {
    return <SectionWrapper></SectionWrapper>;
  }
}
