import React from 'react';
import { useSelector } from 'react-redux';

import { ThemePage } from './theme/ThemePage';
import { FlavorPage } from './flavor/FlavorPage';
import { QuantityPage } from './quantity/QuantityPage';
import { PhotoPage } from './photo/PhotoPage';
import { TextPage } from './text/TextPage';
import { ReviewPage } from './review/ReviewPage';
import { UpsellPage } from './upsell/UpsellPage';

export function CurrentPage(props) {
  const currentPageId = useSelector((state) => state.nav.currentPageId);

  let pageContents;
  switch(currentPageId) {
    case 'theme':
      pageContents = <ThemePage />;
      break;
    case 'flavor':
      pageContents = <FlavorPage />;
      break;
    case 'photo':
      pageContents = <PhotoPage />;
      break;
    case 'text':
      pageContents = <TextPage />;
      break;
    case 'quantity':
      pageContents = <QuantityPage />;
      break;
    case 'review':
      pageContents = <ReviewPage />;
      break;
    case 'upsell':
      pageContents = <UpsellPage />;
      break;
    default:
      console.error('Unrecognized pageId: ' + currentPageId);
      pageContents = <></>;
  }

  return (
    <section className={props.className}>
      {pageContents}
    </section>
  );
}
