import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { selectMissingPage } from '../pages/customSlice';
import { setPage, setHighlightMissingPage } from './navSlice';

import styles from './NavItem.module.scss';

export function NavItem(props) {
  const highlightThisPage = (props.pageId === useSelector((state) => state.nav.highlightMissingPageId));
  const isCurrentPage     = (props.pageId === useSelector((state) => state.nav.currentPageId));
  const missingPage       = useSelector((state) => selectMissingPage(state, props.pageId));

  const dispatch = useDispatch();

  const navItemClasses = classNames(
    styles.navItem,
    { [styles.currentPage]: isCurrentPage },
  );

  function handleClick() {
    if(missingPage !== null) {
      dispatch(setHighlightMissingPage(missingPage));
    } else {
      dispatch(setPage(props.pageId));
    }
  }

  return (
    <div className={navItemClasses} onClick={handleClick}>
      {highlightThisPage ? <div className={styles.highlightPage}>{props.highlightMessage}</div> : ""}
      <div className={styles.name}>{props.name}</div>
    </div>
  );
}
