import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SelectItem } from '../../../components/SelectItem';
import { setCaseSize } from '../customSlice';

import { getVariant } from '../../shopify/myJonesShopifyAPI';


export function CaseSizeItem(props) {
  const currentCaseSize = useSelector((state) => state.custom.caseSize);
  const dispatch = useDispatch();

  const product = useSelector((state) => state.shopify.product);
  const theme   = useSelector((state) => state.custom.theme);
  const flavor  = useSelector((state) => state.custom.flavor);
  const variant = getVariant(product, theme, flavor, props.caseSize);

  function clickCaseSize() {
    dispatch(setCaseSize(props.caseSize));
  }

  return (
    <SelectItem
     onClick={clickCaseSize}
     isActive={currentCaseSize === props.caseSize}
    >
      {props.caseSize} Bottles per Case - {" "}
      {variant.compare_at_price ? <s>${variant.compare_at_price}</s> : ""}
      {" "} ${variant.price}
    </SelectItem>
  );
}
