import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './QuantityInput.module.css';
import { setQuantity } from '../customSlice';


export function QuantityInput() {
  const currentQuantity = useSelector((state) => state.custom.quantity);
  const dispatch = useDispatch();

  function updateQuantity(e) {
    dispatch(setQuantity(e.target.value));
  }

  return <input className={styles.input} type="number" min={1} max={1000} value={currentQuantity} onChange={updateQuantity} />;
}
