import React from 'react';
import { useSelector } from 'react-redux';

import { getAvailableCaseSizes } from '../../shopify/myJonesShopifyAPI';

import styles from './QuantityPage.module.scss';
import { CaseSizeItem } from './CaseSizeItem';
import { QuantityInput } from './QuantityInput';
import { ExplanationParagraph } from '../../../components/ExplanationParagraph';

export function QuantityPage() {
  const product = useSelector((state) => state.shopify.product);
  const theme   = useSelector((state) => state.custom.theme);
  const flavor  = useSelector((state) => state.custom.flavor);

  const currentCaseSize = useSelector((state) => state.custom.caseSize);

  const caseSizes = getAvailableCaseSizes(product, theme, flavor);
  const caseSizesElements = caseSizes.map((caseSize, i) => <CaseSizeItem caseSize={caseSize} key={i} />);

  return (
    <section>
      <h2>Choose a Case Size</h2>
      {caseSizesElements}

      <h2 className={styles.middleHeading}>How Many Cases?</h2>
      <QuantityInput />

      {product.handle === 'myjones-custom-labeled-soda' && /* exclude Dog Soda */
        <ExplanationParagraph>
          <strong>DISCOUNTS!</strong><br />
          <strong>Order 2 x 12 packs = 10% off</strong> 2 x 12 custom pks, use code "<strong><kbd>myjones10</kbd></strong>"<br />
          <strong>Order 10 or more 12 pks = 15% off</strong> each 12-pk, <strong><kbd>auto applied at checkout</kbd></strong><br />
          <strong>Order 20 or more 12 pks = 20% off</strong> each 12-pk, <strong><kbd>auto applied at checkout</kbd></strong><br />
          Must have min qty for discount apply. Higher tier discounts available. <a href="/pages/corp-myjones" target="_blank">Please click here for more info on tier discounts and for corporate ordering information.</a>
        </ExplanationParagraph>
      }

      {currentCaseSize === '1' &&
        <ExplanationParagraph>Limit 2 single custom bottles per order, no exceptions.</ExplanationParagraph>
      }
    </section>
  );
}

