import React from 'react';
import { useSelector } from 'react-redux';

import { ExplanationParagraph } from '../../../components/ExplanationParagraph';
import { selectCurrentVariant } from '../customSlice';

export function ReviewPage() {
  const caseSize = useSelector((state) => state.custom.caseSize);
  const quantity = useSelector((state) => state.custom.quantity);
  const variant = useSelector((state) => selectCurrentVariant(state));

  const totalPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    variant.price * quantity
  );

  return (
    <section>
      <h2>Review Your Order</h2>

      <div>Bottles per Case: {caseSize}</div>
      <div>Cases: {quantity}</div>

      <div>Subtotal: {totalPrice}</div>

      <ExplanationParagraph>Production of your order will take <strong>7-10 business days</strong>. Shipping is an <strong><em>additional</em> 2-10 business days</strong>.</ExplanationParagraph>
      <ExplanationParagraph><a href="https://www.jonessoda.com/policies/shipping-policy" target="_blank">View Shipping Policy</a></ExplanationParagraph>

    </section>
  );
}

